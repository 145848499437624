import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  ISession,
  ISessionUpdate,
  ISessionCreate,
  ISlackAuth,
  IQuestionUpdate,
  IQuestionCreate,
  IBinarySuggestionData,
  IStudyData,
  IQuestionOrderMapping,
  IBetaSigneeCreate,
  IBetaSignee,
  ITransaction,
  IRecruitParams,
  ILucidSettings,
  IProlificSettings,
  IQuestionImageUpdate,
  IContract,
  IQuestionVideo,
  IPersonaUpdate,
  IPersonaCreate
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: string, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async deleteUser(token: string, userId: string) {
    return axios.delete(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async activateUser(token: string, confirmation_code: string, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/activate/${confirmation_code}`, data);
  },
  async isAccountActive(confirmation_code: string) {
    return axios.get<boolean>(`${apiUrl}/api/v1/is-active/${confirmation_code}`);
  },
  async lucidSettings(token: string) {
    return axios.get(`${apiUrl}/api/v1/users/admin/lucid`, authHeaders(token));
  },
  async updateLucidSettings(token: string, data: ILucidSettings) {
    return axios.put(`${apiUrl}/api/v1/users/admin/lucid`, data, authHeaders(token));
  },
  async prolificSettings(token: string) {
    return axios.get(`${apiUrl}/api/v1/users/admin/prolific`, authHeaders(token));
  },
  async updateProlificSettings(token: string, data: IProlificSettings) {
    return axios.put(`${apiUrl}/api/v1/users/admin/prolific`, data, authHeaders(token));
  },
  // Questions
  async deleteQuestion(token: string, questionId: string) {
    return axios.delete(`${apiUrl}/api/v1/questions/${questionId}`, authHeaders(token));
  },
  async updateContract(token: string, data: IContract[]) {
    return axios.put(`${apiUrl}/api/v1/contracts/`, data, authHeaders(token));
  },
  async updateQuestion(token: string, questionId: string, data: IQuestionUpdate) {
    return axios.put(`${apiUrl}/api/v1/questions/${questionId}`, data, authHeaders(token));
  },
  async createQuestions(token: string, sessionId: string, data: IQuestionCreate[]) {
    return axios.post(`${apiUrl}/api/v1/questions/?session_id=${sessionId}`, data, authHeaders(token));
  },
  async getSuggestions(token: string, data: IBinarySuggestionData) {
    return axios.post(`${apiUrl}/api/v1/questions/binary_suggestions`, data, authHeaders(token));
  },
  async updateQuestionImageCaption(token: string, questionImageId: string, data: IQuestionImageUpdate) {
    return axios.put(`${apiUrl}/api/v1/questions/question_image/${questionImageId}`, data, authHeaders(token));
  },
  async deleteQuestionImage(token: string, questionImageId: string) {
    return axios.delete(`${apiUrl}/api/v1/questions/question_image/${questionImageId}`, authHeaders(token));
  },
  async createQuestionVideo(token: string, questionId: string, data: IQuestionVideo[]) {
    return axios.post(`${apiUrl}/api/v1/questions/question_video/${questionId}`, data, authHeaders(token));
  },  
  async deleteQuestionVideo(token: string, questionVideoId: string) {
    return axios.delete(`${apiUrl}/api/v1/questions/question_video/${questionVideoId}`, authHeaders(token));
  },
  async getQuestionResponses(token: string, questionId: string, pageLength: number, pageNumber: number, search: string, selectedScores: number[] = [], as_csv = false ) {
    return axios.get(`${apiUrl}/api/v1/answers/participants_with_response`, {
      params: {
        question_id: questionId,
        page_length: pageLength,
        page_number: pageNumber,
        search: search,
        selectedScores: selectedScores.join(','),
        as_csv: as_csv,
      },
      headers: authHeaders(token).headers,
    });
  },
  async getEstimatesFiltered(token: string, questionId: string, data: { question_id: string; contracts: string[] }[]) {
    return axios.post(`${apiUrl}/api/v1/questions/estimates_filtered/${questionId}`, data, authHeaders(token));
  },
  async getShuffledReason(token: string, questionId: string, search: string) {
    return axios.get(`${apiUrl}/api/v1/questions/search_reasons_shuffled/${questionId}`, {
      params: {
        search: search,
      },
      headers: authHeaders(token).headers,
    });
  },
  async getLabelCoverageInReasons(token: string, questionId: string, search: string) {
    return axios.get(`${apiUrl}/api/v1/questions/label_coverage_in_reasons/${questionId}`, {
      params: {
        label: search,
      },
      headers: authHeaders(token).headers,
    });
  },
  async getTotalLabelCoverageInReasons(token: string, questionId: string, labels: string[]) {
    return axios.post(`${apiUrl}/api/v1/questions/total_label_coverage_in_reasons/${questionId}`, labels, authHeaders(token));
  },
  async topicClassificationWithLabels(token: string, questionId: string, candidate_labels: {label: string; count: number|null}[]) {
    return axios.post(`${apiUrl}/api/v1/questions/topic_classification_with_labels/${questionId}`, candidate_labels, authHeaders(token));
  },
  async topicSuggestion(token: string, questionId: string) {
    return axios.post(`${apiUrl}/api/v1/questions/topic_suggestion/${questionId}`, null, authHeaders(token));
  },
  async checkTopicClassificationProgress(token: string, questionId: string, data: { question_id: string; contracts: string[]}[] ) {
    return axios.post(`${apiUrl}/api/v1/questions/check_topic_classification_progress/${questionId}`, data, authHeaders(token));
  },
  // Sessions
  async getSessionData(token: string, sessionId: string) {
    return axios.get<IStudyData>(`${apiUrl}/api/v1/sessions/data/${sessionId}`, authHeaders(token));
  },
  async getPublicStudyData(sessionId: string) {
    return axios.get<IStudyData>(`${apiUrl}/api/v1/sessions/public/data/${sessionId}`);
  },
  async getSessions(token: string) {
    return axios.get<ISession[]>(`${apiUrl}/api/v1/sessions/`, authHeaders(token));
  },
  async getAllSessions(token: string) {
    return axios.get<ISession[]>(`${apiUrl}/api/v1/sessions/all/`, authHeaders(token));
  },
  async setSessionPublic(token: string, sessionId: string) {
    return axios.put<ISession>(`${apiUrl}/api/v1/sessions/set-public/${sessionId}`, {}, authHeaders(token));
  },
  async updateSession(token: string, sessionId: string, data: ISessionUpdate) {
    return axios.put(`${apiUrl}/api/v1/sessions/${sessionId}`, data, authHeaders(token));
  },
  async createSession(token: string, data: ISessionCreate) {
    return axios.post(`${apiUrl}/api/v1/sessions/`, data, authHeaders(token));
  },
  async deleteSession(token: string, sessionId: string) {
    return axios.delete(`${apiUrl}/api/v1/sessions/${sessionId}`, authHeaders(token));
  },
  async copySession(token: string, sessionId: string) {
    return axios.get(`${apiUrl}/api/v1/sessions/copy/${sessionId}`, authHeaders(token));
  },
  async updateQuestionsOrder(token: string, sessionId: string, data: IQuestionOrderMapping[]) {
    return axios.put(`${apiUrl}/api/v1/sessions/question-order/${sessionId}`, data, authHeaders(token));
  },
  async recruitParticipants(token: string, sessionId: string, params: IRecruitParams) {
    return axios.put(`${apiUrl}/api/v1/sessions/recruit/${sessionId}`, params, authHeaders(token));
  },
  async recruitAI(token: string, sessionId: string, count: number) {
    return axios.post(`${apiUrl}/api/v1/sessions/recruit_ai/${sessionId}/${count}`, null, authHeaders(token));
  },
  async killAgents(token: string, sessionId: string, count: number) {
    return axios.post(`${apiUrl}/api/v1/sessions/kill_agents/${sessionId}/${count}`, null, authHeaders(token));
  },
  async generateStudy(token: string, title: string) {
    return axios.post(`${apiUrl}/api/v1/sessions/generate-study`, {'title': title}, authHeaders(token));
  },
  async generateSingleQuestion(token: string, data: object) {
    return axios.post(`${apiUrl}/api/v1/sessions/generate-single-question`, data, authHeaders(token));
  },
  async generateSingleScenario(token: string, data: object) {
    return axios.post(`${apiUrl}/api/v1/sessions/generate-contract`, data, authHeaders(token));
  },
  async countParticipants(token: string, sessionId: string, params: IRecruitParams) {
    return axios.post(`${apiUrl}/api/v1/sessions/get-participant-count/`, params, authHeaders(token));
  },
  async getLucidAudienceEstimate(token: string, sessionId: string, params: IRecruitParams) {
    return axios.post(`${apiUrl}/api/v1/sessions/get-lucid-audience-estimate/`, params, authHeaders(token));
  },
  async startSession(sessionId: string, urlParams: string, participant_id?: string) {
    const url = new URL(`${apiUrl}/api/v1/bot/${sessionId}`);
    const params = new URLSearchParams(urlParams);
    if (participant_id) params.set('started_participant_id', participant_id);
    url.search = params.toString();
    return axios.get(url.toString());
  },
  async checkSession(token: string, sessionId: string) {
    return axios.get(`${apiUrl}/api/v1/sessions/check/${sessionId}`, authHeaders(token));
  },
  async downloadSessionParticipantEmailsCSV(token: string, sessionId: string) {
    return axios.get(`${apiUrl}/api/v1/sessions/download-participant-emails-csv/${sessionId}`, authHeaders(token));
  },
  // Participant
  async createParticipant(sessionId: string, lucidRid?: string) {
    return axios.post(`${apiUrl}/api/v1/participants/new/${sessionId}`, { lucid_rid: lucidRid });
  },
  // Slack
  async verifySlack(token: string, data: ISlackAuth) {
    return axios.put(`${apiUrl}/api/v1/slack`, data, authHeaders(token));
  },
  // Social
  async socialLogin(provider: string, code?: string, token?: string) {
    return axios.post(`${apiUrl}/api/v1/login/social`, {
      provider: provider,
      code: code,
      token: token
    });
  },
  async signUp(data: IBetaSigneeCreate) {
    return axios.post(`${apiUrl}/api/v1/signup/`, data);
  },
  async getBetaSignees(token: string) {
    return axios.get<IBetaSignee[]>(`${apiUrl}/api/v1/signup/`, authHeaders(token));
  },
  async approveBetaSignee(token: string, betaSigneeId: string) {
    return axios.put(`${apiUrl}/api/v1/signup/approve/${betaSigneeId}`, {}, authHeaders(token));
  },
  async deleteBetaSignee(token: string, betaSigneeId: string) {
    return axios.delete(`${apiUrl}/api/v1/signup/${betaSigneeId}`, authHeaders(token));
  },
  // Credits
  async stripeSessionCreate(token: string, product: string) {
    return axios.post(
      `${apiUrl}/api/v1/payments/create-checkout-session`, { product: product }, authHeaders(token)
    );
  },
  async stripeSessionCreateSubscription(token: string, product: string) {
    return axios.post(
      `${apiUrl}/api/v1/payments/create-checkout-session-subscription`, { product: product }, authHeaders(token)
    );
  },
  async getTransactions(token: string, userId: string) {
    return axios.get<ITransaction[]>(`${apiUrl}/api/v1/users/transactions/${userId}`, authHeaders(token));
  },
  async addUserCredits(token: string, userId: string, credits: number, note?: string) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/add-credit/${userId}`, { credits: credits, note: note }, authHeaders(token)
    );
  },
  async getPersonas(token: string, pageLength: number, pageNumber: number, search: string) {
    return axios.get(`${apiUrl}/api/v1/simulator/personas`, {
      params: {
        page_length: pageLength,
        page_number: pageNumber,
        search: search,
      },
      headers: authHeaders(token).headers,
    });
  },
  async importPersonas(token: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${apiUrl}/api/v1/simulator/personas/import`, formData, {
      headers: authHeaders(token).headers,
    });
  },
  async deletePersona(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/simulator/personas/${id}`, {
      headers: authHeaders(token).headers,
    });
  },
  async updatePersona(token: string, id: number, personaIn: IPersonaUpdate) {
    return axios.patch(`${apiUrl}/api/v1/simulator/personas/${id}`, personaIn, authHeaders(token));
  },
  async createPersona(token: string, id: number, personaIn: IPersonaCreate) {
    return axios.post(`${apiUrl}/api/v1/simulator/personas`, personaIn, authHeaders(token));
  },
  async saveAISettings(token: string, data: object) {
    return axios.post(`${apiUrl}/api/v1/simulator/ai-settings`, data, authHeaders(token));
  },
  async getAISettings(token: string) {
    return axios.get(`${apiUrl}/api/v1/simulator/ai-settings`, authHeaders(token));
  },
};
